export default {
  canvasDefaultTransparent: 'rgba(13,17,23,0)',
  pageHeaderBg: '#0d1117',
  marketingIcon: {primary: '#79c0ff', secondary: '#1f6feb'},
  diffBlob: {
    addition: {
      numText: '#c9d1d9',
      fg: '#c9d1d9',
      numBg: 'rgba(88,166,255,0.3)',
      lineBg: 'rgba(56,139,253,0.15)',
      wordBg: 'rgba(56,139,253,0.4)',
    },
    deletion: {
      numText: '#c9d1d9',
      fg: '#c9d1d9',
      numBg: 'rgba(212,118,22,0.3)',
      lineBg: 'rgba(212,118,22,0.15)',
      wordBg: 'rgba(212,118,22,0.4)',
    },
    hunk: {numBg: 'rgba(56,139,253,0.4)'},
    expander: {icon: '#8b949e'},
    selectedLineHighlightMixBlendMode: 'screen',
  },
  diffstat: {deletionBorder: 'rgba(240,246,252,0.1)', additionBorder: 'rgba(240,246,252,0.1)', additionBg: '#58a6ff'},
  searchKeyword: {hl: 'rgba(210,153,34,0.4)'},
  prettylights: {
    syntax: {
      comment: '#8b949e',
      constant: '#79c0ff',
      entity: '#d2a8ff',
      storageModifierImport: '#c9d1d9',
      entityTag: '#a5d6ff',
      keyword: '#ec8e2c',
      string: '#a5d6ff',
      variable: '#fdac54',
      brackethighlighterUnmatched: '#d47616',
      invalidIllegalText: '#f0f6fc',
      invalidIllegalBg: '#6c3906',
      carriageReturnText: '#f0f6fc',
      carriageReturnBg: '#914d04',
      stringRegexp: '#a5d6ff',
      markupList: '#f2cc60',
      markupHeading: '#1f6feb',
      markupItalic: '#c9d1d9',
      markupBold: '#c9d1d9',
      markupDeletedText: '#ffe2bb',
      markupDeletedBg: '#4e2906',
      markupInsertedText: '#cae8ff',
      markupInsertedBg: '#0c2d6b',
      markupChangedText: '#ffe2bb',
      markupChangedBg: '#4e2906',
      markupIgnoredText: '#c9d1d9',
      markupIgnoredBg: '#1158c7',
      metaDiffRange: '#d2a8ff',
      brackethighlighterAngle: '#8b949e',
      sublimelinterGutterMark: '#484f58',
      constantOtherReferenceLink: '#a5d6ff',
    },
  },
  codemirror: {
    text: '#c9d1d9',
    bg: '#0d1117',
    guttersBg: '#0d1117',
    guttermarkerText: '#0d1117',
    guttermarkerSubtleText: '#6e7681',
    linenumberText: '#8b949e',
    cursor: '#c9d1d9',
    selectionBg: 'rgba(56,139,253,0.4)',
    activelineBg: 'rgba(110,118,129,0.1)',
    matchingbracketText: '#c9d1d9',
    linesBg: '#0d1117',
    syntax: {
      comment: '#8b949e',
      constant: '#79c0ff',
      entity: '#d2a8ff',
      keyword: '#ec8e2c',
      storage: '#ec8e2c',
      string: '#a5d6ff',
      support: '#79c0ff',
      variable: '#fdac54',
    },
  },
  checks: {
    bg: '#010409',
    runBorderWidth: '1px',
    containerBorderWidth: '1px',
    textPrimary: '#c9d1d9',
    textSecondary: '#8b949e',
    textLink: '#58a6ff',
    btnIcon: '#8b949e',
    btnHoverIcon: '#c9d1d9',
    btnHoverBg: 'rgba(110,118,129,0.1)',
    inputText: '#8b949e',
    inputPlaceholderText: '#6e7681',
    inputFocusText: '#c9d1d9',
    inputBg: '#161b22',
    inputShadow: '0 0 0 1px (obj) => (0, get_1.default)(obj, path)',
    donutError: '#d47616',
    donutPending: '#d29922',
    donutSuccess: '#388bfd',
    donutNeutral: '#8b949e',
    dropdownText: '#c9d1d9',
    dropdownBg: '#161b22',
    dropdownBorder: '#30363d',
    dropdownShadow: 'rgba(1,4,9,0.3)',
    dropdownHoverText: '#c9d1d9',
    dropdownHoverBg: 'rgba(110,118,129,0.1)',
    dropdownBtnHoverText: '#c9d1d9',
    dropdownBtnHoverBg: 'rgba(110,118,129,0.1)',
    scrollbarThumbBg: 'rgba(110,118,129,0.4)',
    headerLabelText: '#8b949e',
    headerLabelOpenText: '#c9d1d9',
    headerBorder: '#21262d',
    headerIcon: '#8b949e',
    lineText: '#8b949e',
    lineNumText: '#6e7681',
    lineTimestampText: '#6e7681',
    lineHoverBg: 'rgba(110,118,129,0.1)',
    lineSelectedBg: 'rgba(56,139,253,0.15)',
    lineSelectedNumText: '#58a6ff',
    lineDtFmText: '#ffffff',
    lineDtFmBg: '#9e6a03',
    gateBg: 'rgba(187,128,9,0.15)',
    gateText: '#8b949e',
    gateWaitingText: '#d29922',
    stepHeaderOpenBg: '#161b22',
    stepErrorText: '#d47616',
    stepWarningText: '#d29922',
    loglineText: '#8b949e',
    loglineNumText: '#6e7681',
    loglineDebugText: '#a371f7',
    loglineErrorText: '#8b949e',
    loglineErrorNumText: '#6e7681',
    loglineErrorBg: 'rgba(212,118,22,0.15)',
    loglineWarningText: '#8b949e',
    loglineWarningNumText: '#d29922',
    loglineWarningBg: 'rgba(187,128,9,0.15)',
    loglineCommandText: '#58a6ff',
    loglineSectionText: '#58a6ff',
    ansi: {
      black: '#0d1117',
      blackBright: '#161b22',
      white: '#b1bac4',
      whiteBright: '#b1bac4',
      gray: '#6e7681',
      red: '#ec8e2c',
      redBright: '#fdac54',
      green: '#58a6ff',
      greenBright: '#79c0ff',
      yellow: '#d29922',
      yellowBright: '#e3b341',
      blue: '#58a6ff',
      blueBright: '#79c0ff',
      magenta: '#bc8cff',
      magentaBright: '#d2a8ff',
      cyan: '#76e3ea',
      cyanBright: '#b3f0ff',
    },
  },
  project: {headerBg: '#0d1117', sidebarBg: '#161b22', gradientIn: '#161b22', gradientOut: 'rgba(22,27,34,0)'},
  mktg: {
    btn: {
      bg: '#f6f8fa',
      shadow: {
        outline: 'rgb(255 255 255 / 25%) 0 0 0 1px inset',
        focus: 'rgb(255 255 255 / 25%) 0 0 0 4px',
        hover:
          '0 4px 7px rgba(0, 0, 0, 0.15), 0 100px 80px rgba(255, 255, 255, 0.02), 0 42px 33px rgba(255, 255, 255, 0.024), 0 22px 18px rgba(255, 255, 255, 0.028), 0 12px 10px rgba(255, 255, 255, 0.034), 0 7px 5px rgba(255, 255, 255, 0.04), 0 3px 2px rgba(255, 255, 255, 0.07)',
        hoverMuted: 'rgb(255 255 255) 0 0 0 2px inset',
      },
    },
  },
  avatar: {
    bg: 'rgba(255,255,255,0.1)',
    border: 'rgba(240,246,252,0.1)',
    stackFade: '#30363d',
    stackFadeMore: '#21262d',
    childShadow: '-2px -2px 0 #0d1117',
  },
  topicTag: {border: 'rgba(0,0,0,0)'},
  counter: {border: 'rgba(0,0,0,0)'},
  selectMenu: {backdropBorder: '#484f58', tapHighlight: 'rgba(48,54,61,0.5)', tapFocusBg: '#0c2d6b'},
  overlay: {shadow: '0 0 0 1px #30363d, 0 16px 32px rgba(1,4,9,0.85)'},
  header: {text: 'rgba(255,255,255,0.7)', bg: '#161b22', divider: '#8b949e', logo: '#f0f6fc'},
  headerSearch: {bg: '#0d1117', border: '#30363d'},
  sidenav: {selectedBg: '#21262d'},
  menu: {bgActive: '#161b22'},
  input: {disabledBg: 'rgba(110,118,129,0)'},
  timeline: {badgeBg: '#21262d'},
  ansi: {
    black: '#484f58',
    blackBright: '#6e7681',
    white: '#b1bac4',
    whiteBright: '#ffffff',
    gray: '#6e7681',
    red: '#ec8e2c',
    redBright: '#fdac54',
    green: '#58a6ff',
    greenBright: '#79c0ff',
    yellow: '#d29922',
    yellowBright: '#e3b341',
    blue: '#58a6ff',
    blueBright: '#79c0ff',
    magenta: '#bc8cff',
    magentaBright: '#d2a8ff',
    cyan: '#39c5cf',
    cyanBright: '#56d4dd',
  },
  btn: {
    text: '#c9d1d9',
    bg: '#21262d',
    border: 'rgba(240,246,252,0.1)',
    shadow: '0 0 transparent',
    insetShadow: '0 0 transparent',
    hoverBg: '#30363d',
    hoverBorder: '#8b949e',
    activeBg: 'hsla(212,12%,18%,1)',
    activeBorder: '#6e7681',
    selectedBg: '#161b22',
    focusBg: '#21262d',
    focusBorder: '#8b949e',
    focusShadow: '0 0 0 3px rgba(139,148,158,0.3)',
    shadowActive: 'inset 0 0.15em 0.3em rgba(1,4,9,0.15)',
    shadowInputFocus: '0 0 0 0.2em rgba(31,111,235,0.3)',
    counterBg: '#30363d',
    primary: {
      text: '#ffffff',
      bg: '#1f6feb',
      border: 'rgba(240,246,252,0.1)',
      shadow: '0 0 transparent',
      insetShadow: '0 0 transparent',
      hoverBg: '#388bfd',
      hoverBorder: 'rgba(240,246,252,0.1)',
      selectedBg: '#1f6feb',
      selectedShadow: '0 0 transparent',
      disabledText: 'rgba(255,255,255,0.5)',
      disabledBg: 'rgba(31,111,235,0.6)',
      disabledBorder: 'rgba(240,246,252,0.1)',
      focusBg: '#1f6feb',
      focusBorder: 'rgba(240,246,252,0.1)',
      focusShadow: '0 0 0 3px rgba(46,164,79,0.4)',
      icon: '#ffffff',
      counterBg: 'rgba(255,255,255,0.2)',
    },
    outline: {
      text: '#58a6ff',
      hoverText: '#58a6ff',
      hoverBg: '#30363d',
      hoverBorder: 'rgba(240,246,252,0.1)',
      hoverShadow: '0 1px 0 rgba(1,4,9,0.1)',
      hoverInsetShadow: 'inset 0 1px 0 rgba(255,255,255,0.03)',
      hoverCounterBg: 'rgba(255,255,255,0.2)',
      selectedText: '#ffffff',
      selectedBg: '#0d419d',
      selectedBorder: 'rgba(240,246,252,0.1)',
      selectedShadow: '0 0 transparent',
      disabledText: 'rgba(88,166,255,0.5)',
      disabledBg: '#0d1117',
      disabledCounterBg: 'rgba(31,111,235,0.05)',
      focusBorder: 'rgba(240,246,252,0.1)',
      focusShadow: '0 0 0 3px rgba(17,88,199,0.4)',
      counterBg: 'rgba(31,111,235,0.1)',
    },
    danger: {
      text: '#d47616',
      hoverText: '#ffffff',
      hoverBg: '#b76100',
      hoverBorder: '#d47616',
      hoverShadow: '0 0 transparent',
      hoverInsetShadow: '0 0 transparent',
      hoverIcon: '#ffffff',
      hoverCounterBg: 'rgba(255,255,255,0.2)',
      selectedText: '#ffffff',
      selectedBg: '#914d04',
      selectedBorder: '#ec8e2c',
      selectedShadow: '0 0 transparent',
      disabledText: 'rgba(212,118,22,0.5)',
      disabledBg: '#0d1117',
      disabledCounterBg: 'rgba(183,97,0,0.05)',
      focusBorder: '#d47616',
      focusShadow: '0 0 0 3px rgba(212,118,22,0.4)',
      counterBg: 'rgba(183,97,0,0.1)',
      icon: '#d47616',
    },
  },
  underlinenav: {icon: '#6e7681', borderHover: 'rgba(110,118,129,0.4)'},
  actionListItem: {
    inlineDivider: 'rgba(48,54,61,0.48)',
    default: {
      hoverBg: 'rgba(177,186,196,0.12)',
      hoverBorder: 'rgba(0,0,0,0)',
      activeBg: 'rgba(177,186,196,0.2)',
      activeBorder: 'rgba(0,0,0,0)',
      selectedBg: 'rgba(177,186,196,0.08)',
    },
    danger: {hoverBg: 'rgba(212,118,22,0.16)', activeBg: 'rgba(212,118,22,0.24)', hoverText: '#ec8e2c'},
  },
  switchTrack: {
    bg: '#010409',
    border: '#6e7681',
    checked: {
      bg: 'rgba(31,111,235,0.35)',
      hoverBg: 'rgba(31,111,235,0.5)',
      activeBg: 'rgba(31,111,235,0.65)',
      border: '#58a6ff',
    },
  },
  switchKnob: {checked: {bg: '#1f6feb', disabledBg: '#484f58'}},
  segmentedControl: {
    bg: '#161b22',
    button: {hover: {bg: '#30363d'}, active: {bg: '#21262d'}, selected: {border: '#6e7681'}},
  },
  treeViewItem: {chevron: {hoverBg: 'rgba(177,186,196,0.12)'}, directory: {fill: '#8b949e'}},
  fg: {default: '#c9d1d9', muted: '#8b949e', subtle: '#6e7681', onEmphasis: '#ffffff'},
  canvas: {default: '#0d1117', overlay: '#161b22', inset: '#010409', subtle: '#161b22'},
  border: {default: '#30363d', muted: '#21262d', subtle: 'rgba(240,246,252,0.1)'},
  shadow: {
    small: '0 0 transparent',
    medium: '0 3px 6px #010409',
    large: '0 8px 24px #010409',
    extraLarge: '0 12px 48px #010409',
  },
  neutral: {
    emphasisPlus: '#6e7681',
    emphasis: '#6e7681',
    muted: 'rgba(110,118,129,0.4)',
    subtle: 'rgba(110,118,129,0.1)',
  },
  accent: {fg: '#58a6ff', emphasis: '#1f6feb', muted: 'rgba(56,139,253,0.4)', subtle: 'rgba(56,139,253,0.15)'},
  success: {fg: '#58a6ff', emphasis: '#1f6feb', muted: 'rgba(56,139,253,0.4)', subtle: 'rgba(56,139,253,0.15)'},
  attention: {fg: '#d29922', emphasis: '#9e6a03', muted: 'rgba(187,128,9,0.4)', subtle: 'rgba(187,128,9,0.15)'},
  severe: {fg: '#d47616', emphasis: '#b76100', muted: 'rgba(212,118,22,0.4)', subtle: 'rgba(212,118,22,0.15)'},
  danger: {fg: '#d47616', emphasis: '#b76100', muted: 'rgba(212,118,22,0.4)', subtle: 'rgba(212,118,22,0.15)'},
  open: {fg: '#ec8e2c', emphasis: '#b76100', muted: 'rgba(212,118,22,0.4)', subtle: 'rgba(212,118,22,0.15)'},
  closed: {fg: '#8b949e', emphasis: '#6e7681', muted: 'rgba(110,118,129,0.4)', subtle: 'rgba(110,118,129,0.1)'},
  done: {fg: '#a371f7', emphasis: '#8957e5', muted: 'rgba(163,113,247,0.4)', subtle: 'rgba(163,113,247,0.15)'},
  sponsors: {fg: '#db61a2', emphasis: '#bf4b8a', muted: 'rgba(219,97,162,0.4)', subtle: 'rgba(219,97,162,0.15)'},
  primer: {
    fg: {disabled: '#484f58'},
    canvas: {backdrop: 'rgba(1,4,9,0.8)', sticky: 'rgba(13,17,23,0.95)'},
    border: {active: '#f78166', contrast: 'rgba(255,255,255,0.2)'},
    shadow: {highlight: '0 0 transparent', inset: '0 0 transparent', focus: '0 0 0 3px #0c2d6b'},
  },
  scale: {
    black: '#010409',
    white: '#ffffff',
    gray: [
      '#f0f6fc',
      '#c9d1d9',
      '#b1bac4',
      '#8b949e',
      '#6e7681',
      '#484f58',
      '#30363d',
      '#21262d',
      '#161b22',
      '#0d1117',
    ],
    blue: [
      '#cae8ff',
      '#a5d6ff',
      '#79c0ff',
      '#58a6ff',
      '#388bfd',
      '#1f6feb',
      '#1158c7',
      '#0d419d',
      '#0c2d6b',
      '#051d4d',
    ],
    green: [
      '#cae8ff',
      '#a5d6ff',
      '#79c0ff',
      '#58a6ff',
      '#388bfd',
      '#1f6feb',
      '#1158c7',
      '#0d419d',
      '#0c2d6b',
      '#051d4d',
    ],
    yellow: [
      '#f8e3a1',
      '#f2cc60',
      '#e3b341',
      '#d29922',
      '#bb8009',
      '#9e6a03',
      '#845306',
      '#693e00',
      '#4b2900',
      '#341a00',
    ],
    orange: [
      '#ffe2bb',
      '#ffc981',
      '#fdac54',
      '#ec8e2c',
      '#d47616',
      '#b76100',
      '#914d04',
      '#6c3906',
      '#4e2906',
      '#331c04',
    ],
    red: ['#ffe2bb', '#ffc981', '#fdac54', '#ec8e2c', '#d47616', '#b76100', '#914d04', '#6c3906', '#4e2906', '#331c04'],
    purple: [
      '#eddeff',
      '#e2c5ff',
      '#d2a8ff',
      '#bc8cff',
      '#a371f7',
      '#8957e5',
      '#6e40c9',
      '#553098',
      '#3c1e70',
      '#271052',
    ],
    pink: [
      '#ffdaec',
      '#ffbedd',
      '#ff9bce',
      '#f778ba',
      '#db61a2',
      '#bf4b8a',
      '#9e3670',
      '#7d2457',
      '#5e103e',
      '#42062a',
    ],
    coral: [
      '#ffddd2',
      '#ffc2b2',
      '#ffa28b',
      '#f78166',
      '#ea6045',
      '#cf462d',
      '#ac3220',
      '#872012',
      '#640d04',
      '#460701',
    ],
  },
}
